import { useEffect, useState } from 'react';

export default function useJobs() {
  const [jobs, setJobs] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://genaiz.bamboohr.com/jobs/embed2.php')
      .then((response) => response.text())
      .then((html) => {
        const jobsArr = [];
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const departmentItems = doc.querySelectorAll(
          '.BambooHR-ATS-Department-Item'
        );

        for (const departmentItem of departmentItems) {
          const departmentName = departmentItem
            .querySelector('.BambooHR-ATS-Department-Header')
            .innerText.trim();

          const jobItems = departmentItem.querySelectorAll(
            '.BambooHR-ATS-Jobs-Item'
          );

          for (const jobItem of jobItems) {
            const job = jobItem.querySelector('a');
            const jobUrl = job.href;
            const jobTitle = job.innerText.trim();

            const jobLocation = jobItem
              .querySelector('.BambooHR-ATS-Location')
              .innerText.trim();

            jobsArr.push({
              departmentName,
              jobTitle,
              jobUrl,
              jobLocation,
            });
          }
        }

        setJobs(jobsArr);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  return [jobs, error];
}
