/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';
import useJobs from '../hooks/use-jobs';

import headerJobsSvg from '../img/headers/header-jobs.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import MarkdownContent from '../components/MarkdownContent';
import { Button } from '../components/Buttons';
import AspectRatioImg from '../components/AspectRatioImg';

const Intro = ({ get }) => (
  <Container>
    <AspectRatioImg src={headerJobsSvg} alt="" ratio={912 / 256} />
    <Styled.h1 sx={{ mt: 13 }}>{get('title')}</Styled.h1>
    <MarkdownContent sx={{ mt: 7 }}>{get('text')}</MarkdownContent>
  </Container>
);

const Openings = ({ get }) => {
  const [jobs, error] = useJobs();

  return (
    <section sx={{ mt: 13 }}>
      <Container>
        <Styled.h3 sx={{ mb: 9 }}>{get('openings.title')}</Styled.h3>
        {jobs ? (
          <div
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 2fr 1.5fr',
              gridGap: 7,
            }}
          >
            {jobs.map((job, i) => (
              <Fragment key={i}>
                <div>{job.departmentName}</div>
                <div>
                  <b>{job.jobTitle}</b>
                </div>
                <div>
                  <Button
                    href={job.jobUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="secondary"
                    outlined
                  >
                    {get('openings.cta')}
                  </Button>
                </div>
              </Fragment>
            ))}
          </div>
        ) : error ? (
          <span sx={{ color: 'muted' }}>{get('openings.error')}</span>
        ) : (
          '...'
        )}
      </Container>
    </section>
  );
};

export const JobsPageTemplate = (props) => (
  <Fragment>
    <Intro {...props} />
    <Openings {...props} />
  </Fragment>
);

const JobsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      page="jobs"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <JobsPageTemplate get={createGet(frontmatter)} />
    </Layout>
  );
};

JobsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default JobsPage;

export const pageQuery = graphql`
  query JobsPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        title
        text
        openings {
          title
          cta
          error
        }
      }
    }
  }
`;
